/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Sora&display=swap');
@import "../node_modules/bootstrap/scss/mixins";
@import "src/assets/scss/variables.scss";
@import "src/assets/scss/bootstrap-theme.scss";
@import "src/assets/scss/form.scss";
@import "src/assets/scss/table.scss";
@import '~@aws-amplify/ui-angular/theme.css';


// @import "src/assets/css/icons/font-awesome/css/all.css";
:root {
  --amplify-font-family: roboto;
  --amplify-primary-color: #84dba2 ;
  --amplify-primary-tint: #1f1f1f;
  --amplify-primary-shade: #1f1f1f;
  --amplify-components-button-primary-background-color: #84dba2 ;
  --amplify-components-button-link-color: #84dba2 ;
  --amplify-components-tabs-item-active-color: #84dba2 ;
  --amplify-components-tabs-item-active-border-color: #84dba2 ;
  --form-color: #424242;
  --amplify-components-background-color: #424242;
    --amplify-colors-background-primary: #424242;
    --amplify-colors-background-secondary: #424242;
    --amplify-colors-background-tertiary: #424242;
    --amplify-colors-border-primary: #424242;
    --amplify-colors-border-secondary: #424242;
    --amplify-colors-border-tertiary: #84dba2;
    --amplify-colors-brand-primary-10: #424242;
    --amplify-colors-brand-primary-20: #84dba2;
    --amplify-colors-brand-primary-40: #84dba2;
    --amplify-colors-brand-primary-60: #84dba2;
    --amplify-colors-brand-primary-80: #84dba2;
    --amplify-colors-brand-primary-90: #84dba2;
    --amplify-colors-brand-primary-100: #84dba2;
    --amplify-colors-font-interactive: var(--amplify-colors-white);
    --amplify-components-heading-color: #84dba2;
    --amplify-components-tabs-item-active-border-color: #84dba2;
    --amplify-components-tabs-item-active-color: #84dba2;
    --amplify-components-tabs-item-color: #84dba2;
    --amplify-components-tabs-item-focus-color: #84dba2;
    --amplify-components-text-color: #84dba2;
    --amplify-components-field-label-color: #84dba2;
}
// html, body {
//   font-family: $primary-font;
// }

[data-amplify-authenticator] {
  --amplify-colors-background-primary: #424242;
  --amplify-colors-background-secondary: #424242;
  --amplify-colors-background-tertiary: #424242;
  --amplify-colors-border-primary: #84dba2;
  --amplify-colors-border-secondary: #84dba2;
  --amplify-colors-border-tertiary: #84dba2;
  --amplify-colors-brand-primary-10: #424242;
  --amplify-colors-brand-primary-20: #84dba2;
  --amplify-colors-brand-primary-40: #84dba2;
  --amplify-colors-brand-primary-60: #84dba2;
  --amplify-colors-brand-primary-80: #84dba2;
  --amplify-colors-brand-primary-90: #84dba2;
  --amplify-colors-brand-primary-100: #84dba2;
  --amplify-colors-font-interactive: var(--amplify-colors-white);
  --amplify-components-heading-color: #84dba2;
  --amplify-components-tabs-item-active-border-color: #84dba2;
  --amplify-components-tabs-item-active-color: #84dba2;
  --amplify-components-tabs-item-color: #84dba2;
  --amplify-components-tabs-item-focus-color: #84dba2;
  --amplify-components-text-color: #84dba2;
  --amplify-components-field-label-color: #84dba2;
  --text-primary-color:  #1f1f1f;
  --text-primary-lighter-color:  #1f1f1f;
  --text-primary-darker-color: #1f1f1f;
}

html, body { height: 100%; }
.main-window{
  min-height: 83vh;
}
body {
  margin: 0;
  font-family: $primary-font;
  background-color: $base-color;
// body
// snackbar
.primary-snackbar{
  background-color: $primary-color;
}

// font styles
p{
  font-size: $primary-font-size;
  font-weight: $primary-font-weight;
}
h1,h2,h3,h4,h5,h6 {
  font-family: $primary-font;
  font-style: normal;
  font-weight: bold;
}
p,label,a {
  font-family: $secondary-font;
  font-style: normal;
}

 // section spacing
.spacing {
  margin-bottom: 15px;
}

a:hover {
  color: $primary-color;
  text-decoration: underline;
}

// buttons
  .success-outline{
      color: $primary-color;
      border-color: $primary-color;
      // hover-color: #84dba2 ;
      margin-top: 1em;
  }
  .success-outline:hover{
      color: $secondary-color;
  }

  .success-fill{
    // color: #84dba2 ;
    border-color: $primary-color;
    background-color: $primary-color;
    margin-top: 1em;
}
.success-fill:hover{
    color: $secondary-color;
}
// icons
i{
  color: $primary-color;
}



// text colors

h3 {
  color:$primary-color;
}

h2 {
  color: $primary-color;
}


h4 {
  color: $primary-color;
}
p{
  color: $secondary-color;
}
label{
  color: $secondary-color;
}

// container space
.container-space{
  margin-left: 1rem;
  margin-right: 1rem;
}

// cards
.bg-card {
      padding: 10px;
      min-height: 100%;
      border: 1px solid $border-color;
      background-color: $card-color;
}
.card-box {
  box-shadow: 0 0 15px 0 rgb(0 0 0 / 20%), 0 0px 5px 0 rgb(0 0 0 / 24%);
  padding: 0.5rem 1rem;
  background-color: $card-color;
}

.bg-box {
  background-color: $base-color;
  border-radius: 5px;
  padding: 20px 30px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}

.padding-card{
padding: 50px;
}
// nav tabs
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color:$btn-text-color;
  background-color:$darker-color;
  border-color: #454d55;
}
.nav-link{
  color: $secondary-color;
}
.nav-tabs{
  border-bottom: 1px solid #454d55;
}
hr{
  border:1px solid  $border-color;
}
.tabs-padding .nav-link{
  padding: 5px 10px ;
}

.tabs-padding .nav-link.active{
  color: $primary-color;
}
.border-line{
  border-bottom: 1px solid $border-color;
}
.text-rotate{
  transform: rotate(270deg);
  position: absolute;
  top: 50%;
  left: 0;
}
  .p-side-list{
    padding-top: 20px;
    padding-left: 10px;
    padding-bottom: 10px;
  }
}//  body-end


.accordion{
  margin-bottom: 50px;
  .card{
      border-color: rgb(55 53 53);
    .card-header{

      h2{
        button{
          text-decoration: none;
          background: none;
          color: #fff;
          outline: none;

          &:hover{
            background: none !important;
          }
          &:focus{
            outline: none;
            box-shadow: none;
          }
        }
        .collapsed{
          color: #84dba2;
        }
      }
    }
    .card-body{
      background: #3a3a3a;
      text-align: left;
    }

  }
}







@import 'src/assets/scss/responsive.scss';
