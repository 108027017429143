
@media screen and (max-width:992px) {
    
    button.btn{
        width: auto !important;
    }

    body .container, 
    body .container-md, 
    body .container-sm {
        max-width: 98%;
    }


    .card-link{      
        div{
      
          p{
            font-size: 12px;
          }
        }
      }


      .pilot-item{

        .first-column{
            span{
                font-size: 12px;
                line-height: normal;
                display: block;
            }
        }

        .candidate-list-time{
            font-size: 12px;
            line-height: normal;
            display: block;
        }
      }


      .detail-box{
        span.text-primary{
            font-size: 12px;
            text-align: center;
        }
      }
      .card-link div p.text-primary{
          font-size: 12px;
      }

      
}
