@import '~@angular/material/theming';
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Theme Config

body {
  --primary-color: #84dba2 ;
  --primary-lighter-color: #daf4e3;
  --primary-darker-color: #67cd86;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$dark-primary-text};
}

$mat-primary: (
  main: #84dba2 ,
  lighter: #daf4e3,
  darker: #67cd86,
  200: #84dba2 , // For slide toggle,
  contrast : (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $dark-primary-text,
  )
);
$theme-primary: mat-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #84dba2 ;
  --accent-lighter-color: #daf4e3;
  --accent-darker-color: #67cd86;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}

$mat-accent: (
  main: #84dba2 ,
  lighter: #daf4e3,
  darker: #67cd86,
  200: #84dba2 , // For slide toggle,
  contrast : (
    main: $dark-primary-text,
    lighter: $dark-primary-text,
    darker: $dark-primary-text,
  )
);
$theme-accent: mat-palette($mat-accent, main, lighter, darker);



$theme: mat-dark-theme($theme-primary, $theme-accent);
$altTheme: mat-light-theme($theme-primary, $theme-accent);

.primary-snackbar{
  background-color: #67cd86
}
.red-snackbar{
  background-color: #F44336
}
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($theme);
