$base-color: #1a1919;
$primary-color: #84dba2 ;
$secondary-color: #f4f4f4;
$darker-color: #67cd86;
$card-color: #2728286b ;
$form-field-color:#1b1c1d;
$dim-color: #575858;
$btn-text-color: #1a1919;
$border-color: #383636;
$table-dark-color:#212529;
$selectd-color: #323a42;
$chart-color:#151515;


$primary-font: roboto;
$secondary-font: arial;
$primary-font-size: 14px;
$primary-font-weight: 300;



