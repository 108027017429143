@import "src/assets/scss/variables.scss";
table tbody td{
    line-height: 12px;
  }
  table tbody tr {
    cursor: pointer;
  }
  .v-middle{
    vertical-align: middle !important;
  }
  
  td{
    vertical-align:middle !important;
  }
  table th,td {
    white-space: nowrap;
  }

.bg-thead {
 
  border-radius: 5px;
  border: 0;
}

.bg-thead th h5 {
  color: $primary-color !important;
  font-weight: 500;
}
table th,
td {
  white-space: nowrap;
}

.table-dark {
  color: $primary-color !important;
}
table{
  tr{
    p{
      margin: 0;
    }

    a{
      i.fa{
        font: normal normal normal 18px/1 FontAwesome;
      }
    }
    
  }
}
#balance{
  p{
    margin-bottom: 0;
  }
}
table.table-md{
  font-size: 12px;
}