@import "src/assets/scss/variables.scss";


body{
    background-color: $base-color!important;
    .text-primary{
        color: $primary-color !important ;
      }
      .text-secondary{
        color: $secondary-color !important;
      }
    .bg-dark{
        background-color: $card-color !important;
    }
    .chart-bg{
        background-color: $chart-color !important;
        color: $primary-color !important;
    }
    .card {
        border-radius: 0 !important;
        background-color: $card-color;
    }
    .text-light{
      color: $dim-color !important;
    }
    .btn-primary{
      background-color: $darker-color !important;
      color: $btn-text-color !important;
      border-color: $darker-color;
    }
    .btn-outline-primary{
      // background-color: $darker-color !important;
      color: $primary-color !important;
      border-color: $primary-color;
    }
   
    .btn-success{
      background-color: $darker-color !important;
      color: $btn-text-color !important;
    }
    .btn-outline{
      color:$primary-color;
      background-color: $base-color;
    }
    
    .btn:hover{
      background-color: $primary-color !important;
      color: $secondary-color !important;
    }
    // button{
    //   background-color: $darker-color !important;
    //   color: $btn-text-color !important;
    // }
    button:hover{
      background-color: $primary-color !important;
      color: $secondary-color !important;
    }
    .table-dark{
      background-color: $table-dark-color !important;
    }
}

