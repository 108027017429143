@import "src/assets/scss/variables.scss";
// dropdown
.select{
    border:none;
  }
  .line{
    border-right: 1px solid $border-color;
    margin-right:1px;
  
  }
  .select-width{
    flex: 1;
    width: 100%;
    margin-bottom: 0 !important;
    border-radius: 4px;
    padding: 5px;
  }
  .common-select{
    margin-bottom: 0 !important;
    border-radius: 4px;
    padding: 5px;
  }
//   .nav-link:focus, .nav-link:hover {
//     color: $primary-color;
//     background: transparent ;
//     border:1px solid $darker-color;
//   }
  // ::placeholder {
  //   color: $darker-color;
  //   opacity: 1; /* Firefox */
  // }
  
  select:active, select:hover {
    background-color: $dim-color !important ; 
    color: $primary-color !important;
  }
  /* toggle switch */
.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }
  
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $dim-color;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 7px;
    bottom: 2px;
    background-color: $secondary-color;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  
  input:checked + .slider {
    background-color: $darker-color;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px $darker-color;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  
  .slider.round {
    border-radius: 34px;
  }
  
  .slider.round:before {
    border-radius: 50%;
  }
  
  /* range slider */
  
  .slidecontainer {
    width: 100%;
  }
  
  .slider {
    -webkit-appearance: none;
    width: 100%;
    height: 25px;
    background: $secondary-color;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }
  
  .slider:hover {
    opacity: 1;
  }
  
  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    background: $darker-color;
    cursor: pointer;
  }
  
  .slider::-moz-range-thumb {
    width: 25px;
    height: 25px;
    background: $darker-color;
    cursor: pointer;
  }
  .mgn-btm-0 {
    margin-bottom: 0 !important;
  }

select,
input,
textarea {
  border: none !important;
  color: $secondary-color !important;
  background-color: $form-field-color !important;
}

input:active,
input:hover {
  background-color: $dim-color !important ;
}
input:focus,
select:focus {
  background-color: $dim-color !important ;
  box-shadow: 0 0 0 0.2rem rgb(132 219 162 / 25%) !important;
  color: $secondary-color !important;
}

.input-group > .input-group-prepend > select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

// custom scrollbar 
/* width */
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  ::-webkit-overflow-scrolling {
    width: 6px;
    height: 6px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px $form-field-color;
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $primary-color;
    border-radius: 10px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: $primary-color;
  }
  input[type=date].form-control, input[type=datetime-local].form-control, input[type=month].form-control, input[type=time].form-control{
    color:$dim-color !important ;
  }
  ::-webkit-calendar-picker-indicator {
    filter: invert(1);
}